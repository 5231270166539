.registration {
  background-color: $primary;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .step-container {
    width: 520px;
    height: auto;
    background-color: $white;
    margin: 24px auto;
    border-radius: 16px;

    &.final-step {
      flex: 1;
    }

    .header {
      padding: 16px 24px;
      border-bottom: 1px solid $gray-background-hover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .status-bar {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 12px;
        align-items: center;
        gap: 48px;

        .back-button {
          &.first-step {
            visibility: hidden;
          }
        }
      }

      .title {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 100% */
        letter-spacing: -0.5px;
        color: $primary;
        max-width: 380px;
        text-align: center;
      }

      .description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.5px;
        color: $content-secondary;
        margin-top: 8px;
      }
    }

    .registration-step-content {
      .body {
        padding: 24px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .footer {
        padding: 12px 24px;
        border-top: 1px solid $gray-background-hover;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .step-container {
      width: 100%;
      flex: 1;
      border-radius: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .header {
        // flex-grow: 0;
        flex: none;

        .status-bar {
          gap: 16px;
        }

        .description {
          text-align: center;
        }
      }

      .registration-step-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        form {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .body {
          flex: 1;
        }
      }
    }

    .step-container > * {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
}
