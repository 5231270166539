:root {
  --gray-light: #B1BAC5;
}

$white: #FFFFFF;
$desert-white: #FBF8F3;

$primary: #081225;
$primary-content: #0E0F0C;
$primary-light: #1C2536;

$dark-background-hover: #060E1E;

$gray-background-active: #C9D4E1;
$gray-quaternary: #ABB3BE;
$gray-border: #2f2b431a;
$gray-background: #F0F1F1;
$gray-background-hover: #F0F3F5;
$gray-content-tertiary: #858B93;
$gray-border-line: #E6E7E9;
$gray-background-border: rgba(22, 51, 0, 0.14);
$gray-border-color: #E5E5E5;
$gray-sidebar-divider: #30343B;

$yellow-highlight: #FFF6E0;
$yellow: #FFD166B2;

$black: #232323;

$red-background: rgba(243, 86, 86, 0.08);
$red-background-hover: rgba(243, 86, 86, 0.10);

$accent: #B7B3F3;
$accent-background-hover: #A5A1DB;
$accent-light: #C1C3EB;

$content-secondary: #505763;
$content-accent: #D2D4FF;

$system-destructive: #E6483D;
$system-destructive-hover: #DB4D4D;
$system-cancelled: #F35656;
$system-warning: #FFD166;
$system-success: #45CE7F;

$black: #0D0C22;

$pink-light: #FDE6E6;
$pink: #f3565666;
$sky-blue: #A5D6FF;
$green-highlight: #E3F8EC;
